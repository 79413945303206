import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import { OrderStatus } from '../Models/OrderStatus';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
var payments = [
  { name: 'Transaction ID', detail: window.location.pathname.split('/')[1] },
  { name: 'Transaction Date', detail: new Date().toString() },
  { name: 'Method', detail: "Paypal" },
];

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));


function SolveTransDate(input: any, rank: string): any {
  if (rank.length === 0) {
    return ""
  }
  if (input) {
    var copiedDate = new Date(Date.parse(input));
    if (copiedDate) {
      input = copiedDate.toUTCString();
    }
}
return input;

}

type MyProps = { TransInfo: OrderStatus}
export default function Review(props: MyProps) {
  const classes = useStyles();
  payments = [
    { name: 'Transaction ID', detail: window.location.pathname.split('/')[1] },
    { name: 'Transaction Date', detail: SolveTransDate(props.TransInfo.UTCPurchaseDate, props.TransInfo.Rank) },
    { name: 'Method', detail: "Stripe or Paypal" },
  ];
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
          <ListItem className={classes.listItem} key={props.TransInfo.Rank}>
          <ListItemText primary={props.TransInfo.Rank} secondary={props.TransInfo.Rank} />
          </ListItem>
          <ListItem className={classes.listItem}>
          <ListItemText primary={`Rank Fully Processed/Received: `}/>
          <Typography variant="body2">{props.TransInfo.Recieved ? <CheckCircleIcon />: <NotInterestedIcon />}</Typography>
          </ListItem>
          
          <ListItem className={classes.listItem}>
          <ListItemText primary={`PvP Bamboo (requires joining Normal PvP Servers) received: `}/>
          <Typography variant="body2">{props.TransInfo.PvPBamboo ? <CheckCircleIcon />: <NotInterestedIcon />}</Typography>
          </ListItem>
          <ListItem className={classes.listItem}>
          <ListItemText primary={`RP Bamboo (requires joining RP Servers) received: `}/>
          <Typography variant="body2">{props.TransInfo.RPBamboo ? <CheckCircleIcon />: <NotInterestedIcon />}</Typography>
          </ListItem>
          <ListItem className={classes.listItem}>
          <ListItemText primary={`KitPvP Bamboo (requires joining KitPvP Servers) received: `}/>
          <Typography variant="body2">{props.TransInfo.KitPvPBamboo ? <CheckCircleIcon />: <NotInterestedIcon />}</Typography>
          </ListItem>
  
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Steam ID:
          </Typography>
          <Typography gutterBottom>{props.TransInfo.Steamid64}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Other Details:
          </Typography>
          <Grid container>
            {payments.map((payment) => (
              <React.Fragment key={payment.name}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.detail}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}