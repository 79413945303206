import React from 'react';
import './App.css';
import Checkout from './Components/Checkout';

function App() {
  return (
    <Checkout></Checkout>
  );
}

export default App;
