import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function Error() {
  
    return (
      <React.Fragment>
          {window.location.pathname.split('/')[1].length === 0 ? <Typography variant="h6" gutterBottom>
          This website is only for active transactions. If you are trying to track your order process after clicking off or without being redirected, it generally takes less than 5 minutes for Paypal/Stripe to process the transaction and less than 30 seconds after that for you to receive your rank in-game. If you have not received your rank after 10 minutes, your transaction was either put on hold or you entered your steamid64 incorrectly. Feel free to reach out to us on <a href="https://pandahut.net/discord">Discord</a> or contact us via <a href="mailto:contact@pandahut.net">contact@pandahut.net</a>.
        </Typography>:  
        <Typography variant="h6" gutterBottom>
           Unexpected error. Please try again later, our Public API is currently unavailable. This does not mean your rank will not process, please check in game for that. You will not need to relog as long as you entered your steamid64 correctly, you will just have to wait for Payment processing.
        </Typography>
}
        </React.Fragment>
    );
}