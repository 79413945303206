import React from 'react';
import Typography from '@material-ui/core/Typography';


export default function Loading() {
  
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
            Loading....
        </Typography>
        </React.Fragment>
    );
}