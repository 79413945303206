import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import Review from './Review';
import { OrderStatus } from '../Models/OrderStatus';
import Loading from './Loading';

import Error from './Error';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: '60%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ['Processing', "Processed", "Delivery"];

function getStepContent(step: any, isLoading: boolean, isError: boolean, orderStatus: OrderStatus): any {
    if (isLoading) {
        return <Loading />
    }
    if (isError) {
        return <Error />
    }
    return <Review TransInfo={orderStatus} />;
}

function GetAPIOrderInfo(TransID: string, setLoading: any, setOrderStatus: any, setActiveStep: any, setError: any,  isError: boolean) {
    fetch("https://api.pandahut.net/api/PaymentProcessing/GetOrderStatus/" + TransID)
    .then((res) => res.json())
    .then(
      (result: OrderStatus) => {
          console.log(`Got Rank info for - ${result.Rank} - ${result}`)
          setLoading(false);
          setOrderStatus(result)
          if (result.Rank === "")
          {
              setActiveStep(0)
          }
          else {
          setActiveStep(result.Recieved ? 3 : 2);
          }
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
          if (isError)
          {
              console.log("Error loading")
              console.error(error)
          setError(true);
          }
      }
    );
}

export default function Checkout() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isError, setError] = React.useState(false);
  const [IntervalRunning, setIntervalRunning] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [orderStatus, setOrderStatus] = React.useState<OrderStatus>({
      "Rank": "invalid",
      "UTCPurchaseDate": new Date(),
      "Steamid64": "",
      "Recieved": false,
      "PvPBamboo": false,
      "RPBamboo": false,
      "KitPvPBamboo": false
  });
  useEffect(() => {
      if (isLoading && isError === false) {
        var TransID = window.location.pathname.split('/')[1];
        console.log(`Trans ID: ${TransID}, length ${TransID.length}`)
        if (TransID === "" || TransID.length === 0) {
            if (isError === false)
            {
              setError(true);
            }
            if (isLoading)
            {
                setLoading(false);
            }
            return;
        }
        GetAPIOrderInfo(TransID, setLoading, setOrderStatus, setActiveStep, setError, isError)
        if (IntervalRunning === false) {
            setIntervalRunning(true)
            setInterval(() => GetAPIOrderInfo(TransID, setLoading, setOrderStatus, setActiveStep, setError, isError), 10000)
        }
        }
  })

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" className={classes.appBar}>
          
        <Toolbar>
        <img _ngcontent-vcm-c43="" src="https://pandahut.net/wp-content/uploads/2015/12/logo_p_h.png" alt="PH Icon" />

        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Order Status - Auto Updating
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment> 
              <React.Fragment>
                {getStepContent(activeStep, isLoading, isError, orderStatus)}
              </React.Fragment>
          
          </React.Fragment>
        </Paper>
        <Typography variant="body2" color="textSecondary" align="center">
        If you need help, please visit our <a href="https://pandahut.net/discord">Discord</a> or contact us via <a href="mailto:contact@pandahut.net">contact@pandahut.net</a> email address.
    </Typography>
      </main>
    </React.Fragment>
  );
}